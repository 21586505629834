import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

import Header from '../components/Header';
import Counter from '../components/counter';

import flames from '../assets/images/barflame.gif';
import nod from '../assets/images/nod.png';

import './layout.css';

const Layout = ({ children }) => {
  const flamePath = flames;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer className="wrapper">
          <h3>Follow Me</h3>
          <ul>
            <li>
              <a href="https://twitter.com/alancrissey/" rel="external">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://github.com/TheRealAlan/" rel="external">
                GitHub
              </a>
            </li>
            <li>
              <a href="https://codepen.io/TheRealAlan/" rel="external">
                CodePen
              </a>
            </li>
          </ul>
          <img src={flamePath} alt="" className="showDark" loading="lazy" />
          <hr />
          <ThemeToggler>
            {({ theme, toggleTheme }) => {
              if (theme === null) {
                return null;
              }

              return (
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      toggleTheme(e.target.checked ? 'dark' : 'light')
                    }
                    checked={theme === 'dark'}
                  />{' '}
                  Night mode{' '}
                  <img
                    src={nod}
                    alt=""
                    style={{ marginLeft: '8px' }}
                    loading="lazy"
                  />
                </label>
              );
            }}
          </ThemeToggler>
          <Counter />
          <p>
            <strong>
              <em>
                Contact the <Link to="/404">WebMaster</Link>
              </em>
            </strong>
          </p>
          <img src={flamePath} alt="" className="showDark" loading="lazy" />
          <hr />
          <cite>
            <small>&copy;2007 to {new Date().getFullYear()} Alan Crissey</small>
          </cite>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
