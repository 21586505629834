import React, { useRef, useEffect, useState } from 'react';

const TIMER_ARRAY = [
  500,
  750,
  1000,
  2000,
  5000,
];

const Counter = () => {
  const [visitCount, setVisitCount] = useState(1);
  const countRef = useRef(visitCount);
  countRef.current = visitCount;

  useEffect(() => {
    let delay = TIMER_ARRAY[Math.floor(Math.random() * TIMER_ARRAY.length)];

    const timer = setInterval(() => {
      setVisitCount(countRef.current++);
    }, delay);

    return () => clearInterval(timer);
  }, [visitCount]);

  return (
    <p>Visited <strong>{visitCount}</strong> times</p>
  )
}

export default Counter;
