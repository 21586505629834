import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './Header.module.css';

const Header = ({ siteTitle = '' }) => (
  <header className={styles.root}>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

export default Header;
